import { useAuth0 } from '@auth0/auth0-react'
import useSWR from 'swr'

import { getUserResearchPanelData } from 'clients/user-research-panel'

const USER_RESEARCH_PANEL_DATA_KEY = 'userResearchPanelData'

export const useUserResearchPanelData = () => {
    const { getAccessTokenSilently, user } = useAuth0()

    return useSWR(
        [USER_RESEARCH_PANEL_DATA_KEY, user?.sub],
        async () => {
            const token = await getAccessTokenSilently()
            return getUserResearchPanelData(token)
        },
        {
            revalidateOnFocus: false,
        }
    )
}
