import { useAuth0 } from '@auth0/auth0-react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { postUserResearchPanelData, updateUserResearchPanelData } from 'clients/user-research-panel'
import { useUserResearchPanelStep } from 'components/UserResearchPanel/useUserResearchPanelSteps'
import { createUserResearchPanelSchema } from 'components/UserResearchPanel/validationSchema'

export type UserResearchPanelFormData = ReturnType<typeof createUserResearchPanelSchema>['__outputType']
export type PartialUserResearchPanelFormData = {
    [K in keyof UserResearchPanelFormData]?: UserResearchPanelFormData[K]
}
type Touched = {
    [K in keyof UserResearchPanelFormData]?: boolean
}
type Errors = {
    [K in keyof UserResearchPanelFormData]?: string | undefined
}

type State = {
    bannerLastDismissed: number | null
    activeStep: number // See steps in useUserResearchPanelSteps.tsx
    isInitialSignup: boolean
    formData: UserResearchPanelFormData
    touched: Touched
    errors: Errors
    showSuccessModal: boolean
    submitError: string | null
    saveError: string | null
    showSaveSuccessNotification: boolean
}

type Action = {
    setBannerLastDismissed: (bannerLastDismissed: State['bannerLastDismissed']) => void
    setIsInitialSignup: (isInitialSignup: State['isInitialSignup']) => void
    setFormData: (formData: object) => void
    updateFormData: (formData: object) => void
    setActiveStep: (activeStep: State['activeStep']) => void
    setTouched: (field: string, isTouched: boolean) => void
    resetForm: () => void
    setErrors: (errors: Errors | ((prev: Errors) => Errors)) => void
    postSubmission: (token: string) => void
    setShowSuccessModal: (showSuccessModal: State['showSuccessModal']) => void
    setSubmitError: (error: string | null) => void
    setSaveError: (error: string | null) => void
    setShowSaveSuccessNotification: (showSaveSuccessNotification: State['showSaveSuccessNotification']) => void
}

export const UserResearchPanelSignupStore = create<State & Action>()(
    persist(
        set => {
            return {
                isInitialSignup: true,
                bannerLastDismissed: null,
                activeStep: 0,
                formData: {} as UserResearchPanelFormData,
                touched: {},
                errors: {},
                showSuccessModal: false,
                submitError: null,
                saveError: null,
                showSaveSuccessNotification: false,
                setIsInitialSignup: (isInitialSignup: State['isInitialSignup']) => {
                    set({ isInitialSignup })
                },
                setBannerLastDismissed: (bannerLastDismissed: State['bannerLastDismissed']) => {
                    set({ bannerLastDismissed })
                },
                setFormData: (formData: State['formData']) => {
                    set({ formData: formData })
                },
                updateFormData: (formData: object) => {
                    set(state => ({ formData: { ...state.formData, ...formData } }))
                },
                setActiveStep: (activeStep: State['activeStep']) => {
                    set({ activeStep })
                },
                resetForm: () => {
                    // We don't want to reset the form data, because we want to keep the data that the user has already entered
                    set({
                        touched: {},
                        errors: {},
                        isInitialSignup: true,
                    })
                },
                setTouched: (field: string, isTouched: boolean) => {
                    set(state => ({
                        touched: { ...state.touched, [field]: isTouched },
                    }))
                },
                setErrors: (errors: Errors | ((prev: Errors) => Errors)) => {
                    set(state => ({ errors: typeof errors === 'function' ? errors(state.errors) : errors }))
                },
                postSubmission: async (token: string) => {
                    try {
                        const formData = UserResearchPanelSignupStore.getState().formData
                        const response = await postUserResearchPanelData(formData, token)

                        if (response.status === 200) {
                            set({ showSuccessModal: true, submitError: null })
                        }
                    } catch (error: unknown) {
                        set({ submitError: (error as AxiosError).response.data.error })
                    }
                },
                setShowSuccessModal: (showSuccessModal: State['showSuccessModal']) => {
                    set({ showSuccessModal })
                },
                setSubmitError: (error: string | null) => {
                    set({ submitError: error })
                },
                setSaveError: (error: string | null) => {
                    set({ saveError: error })
                },
                setShowSaveSuccessNotification: (showSaveSuccessNotification: State['showSaveSuccessNotification']) => {
                    set({ showSaveSuccessNotification })
                },
            }
        },
        {
            name: 'user-research-panel-signup',
            partialize: state => ({
                ...state,
                submitError: null, // Reset on reload
                saveError: null, // Reset on reload
                touched: {},
                errors: {},
            }),
        }
    )
)

type AxiosError = {
    response: {
        data: {
            error: string
        }
    }
}

// userProgress - Give a number between 0 and 100 based on the number of required steps completed
export const useProgress = () => {
    const intl = useIntl()

    const formData = UserResearchPanelSignupStore(state => state.formData)
    const schema = createUserResearchPanelSchema(intl)

    // Helper function to check if a field is required based on current form data
    const isFieldRequired = (fieldPath: string): boolean => {
        try {
            // Create a test object with undefined for the target field
            const testData = { ...formData }
            const pathParts = fieldPath.split('.')
            let current = testData

            // Navigate to the nested location
            for (let i = 0; i < pathParts.length - 1; i++) {
                const key = pathParts[i] as keyof UserResearchPanelFormData
                if (!current[key]) {
                    current[key] = {} as UserResearchPanelFormData[keyof UserResearchPanelFormData]
                }
                current = current[key] as UserResearchPanelFormData
            }
            // Set the target field to undefined
            const lastKey = pathParts[pathParts.length - 1] as keyof UserResearchPanelFormData
            current[lastKey] = undefined

            // Validate the entire object to properly handle conditional logic
            schema.validateSyncAt(fieldPath, testData)
            return false
        } catch (error) {
            return true
        }
    }

    // Recursive function to traverse schema fields
    const traverseSchema = (
        schemaFields: Record<string, Yup.SchemaFieldDescription>,
        parentPath = ''
    ): [number, number] => {
        let totalRequired = 0
        let totalCompleted = 0

        Object.entries(schemaFields).forEach(([fieldName, field]: [string, Yup.SchemaFieldDescription]) => {
            const fullPath = parentPath ? `${parentPath}.${fieldName}` : fieldName

            // Handle nested fields (objects)
            if (field.type === 'object' && 'fields' in field) {
                const [nestedRequired, nestedCompleted] = traverseSchema(field.fields, fullPath)
                totalRequired += nestedRequired
                totalCompleted += nestedCompleted
            } else {
                // Check if the field is required
                if (isFieldRequired(fullPath)) {
                    totalRequired++

                    // Get the value using the full path
                    const value = fullPath
                        .split('.')
                        .reduce<unknown>((obj, key) => (obj as Record<string, unknown>)?.[key], formData)

                    // Check if the field has a valid value
                    if (value !== undefined && value !== '' && value !== null) {
                        if (Array.isArray(value)) {
                            if (value.length > 0) totalCompleted++
                        } else {
                            totalCompleted++
                        }
                    }
                }
            }
        })

        return [totalRequired, totalCompleted]
    }

    const schemaFields = schema.describe().fields
    const [totalRequiredFields, completedRequiredFields] = traverseSchema(schemaFields)

    // Calculate percentage, handle division by zero
    const progress = totalRequiredFields === 0 ? 0 : Math.round((completedRequiredFields / totalRequiredFields) * 100)

    return Math.min(100, progress) // Ensure we don't exceed 100%
}

export const useActiveStep = () => UserResearchPanelSignupStore(state => state.activeStep)
export const useSetActiveStep = () => UserResearchPanelSignupStore(state => state.setActiveStep)
export const useIsCurrentStepValid = () => {
    const activeStep = useActiveStep()
    const step = useUserResearchPanelStep(activeStep)

    if (!step) return false

    const formData = UserResearchPanelSignupStore(state => state.formData)

    const { validationSchema } = step
    // Get fields for current step's schema
    const stepFields = Object.keys(validationSchema.describe().fields)
    // Create object with only the current step's formData
    const stepData = Object.fromEntries(
        stepFields.map(field => [field, formData[field as keyof UserResearchPanelFormData]])
    )
    const isValid = validationSchema.isValidSync(stepData)

    return isValid
}

const useUpdatePanelData = () => {
    const { getAccessTokenSilently } = useAuth0()

    const setSaveError = UserResearchPanelSignupStore(state => state.setSaveError)
    const setShowSaveSuccessNotification = UserResearchPanelSignupStore(state => state.setShowSaveSuccessNotification)

    return async (newData: PartialUserResearchPanelFormData) => {
        try {
            const token = await getAccessTokenSilently()
            await updateUserResearchPanelData(newData, token)
            setShowSaveSuccessNotification(true)
        } catch (error: unknown) {
            setSaveError((error as Error).message)
        }
    }
}

export const useHandleChange = () => {
    const validateField = useValidateField()
    const isInitialSignup = UserResearchPanelSignupStore(state => state.isInitialSignup)
    const updateData = UserResearchPanelSignupStore(state => state.updateFormData)
    const setTouched = UserResearchPanelSignupStore(state => state.setTouched)
    const updatePanelData = useUpdatePanelData()

    return async (key: keyof UserResearchPanelFormData, value: string | string[]) => {
        // Hack: the back-end expects boolean values, but the form inputs return strings
        const processedValue = value === 'true' ? true : value === 'false' ? false : value

        const newData = { [key]: processedValue }
        updateData(newData)
        setTouched(key, true)
        validateField(key, value)

        if (!isInitialSignup) {
            await updatePanelData(newData)
        }
    }
}

export const useHandleBlur = () => {
    const validateField = useValidateField()
    const setTouched = UserResearchPanelSignupStore(state => state.setTouched)

    return (key: keyof UserResearchPanelFormData, value: string | string[]) => {
        setTouched(key, true)
        validateField(key, value)
    }
}

export const useValidateStep = () => {
    const activeStep = useActiveStep()
    const step = useUserResearchPanelStep(activeStep)

    const formData = UserResearchPanelSignupStore(state => state.formData)
    const setTouched = UserResearchPanelSignupStore(state => state.setTouched)
    const setErrors = UserResearchPanelSignupStore(state => state.setErrors)

    if (!step || !formData) return { isValid: false, validate: () => {} }

    const { validationSchema } = step
    // Get fields for current step's schema
    const stepFields = Object.keys(validationSchema.describe().fields)
    // Create object with only current step's formData
    const stepData = Object.fromEntries(
        stepFields.map(field => [field, formData[field as keyof UserResearchPanelFormData]])
    )
    const isValid = validationSchema.isValidSync(stepData)

    // Only validate and update errors if the step is invalid
    const validate = (): void => {
        try {
            validationSchema.validateSync(stepData, { abortEarly: false })
            // Clear errors for this step's fields
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors }
                stepFields.forEach(field => delete newErrors[field as keyof UserResearchPanelFormData])
                return newErrors
            })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                // Set touched and errors for invalid fields
                err.inner.forEach(error => {
                    if (error.path) {
                        setTouched(error.path, true)
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            [error.path!]: error.message,
                        }))
                    }
                })
            }
        }
    }

    return {
        isValid,
        validate,
    }
}

export const useValidateField = () => {
    const intl = useIntl()
    const setErrors = UserResearchPanelSignupStore(state => state.setErrors)
    const formData = UserResearchPanelSignupStore(state => state.formData)

    return (field: keyof UserResearchPanelFormData, value: string | string[]) => {
        setErrors((prevErrors: Errors) => {
            const newErrors = { ...prevErrors }
            try {
                createUserResearchPanelSchema(intl).validateSyncAt(field, {
                    ...formData,
                    [field]: value,
                })
                delete newErrors[field]
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    newErrors[field] = err.message
                }
            }
            return newErrors
        })
    }
}
